import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import VerificationForm from "../components/VerificationForm";
import PasswordInput from "../components/PasswordInput";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { storeTokenAndRedirect } from "../lib/tokenHelper";
import { applyMetaAndLoadTags } from '../lib/trackingTags'

import "./Signup.css";

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name is too short")
    .max(50, "First name is too long")
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]*$/, "First name is invalid")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name is too short")
    .max(50, "Last name is too long")
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]*$/, "Last name is invalid")
    .required("Last name is required"),
  password: Yup.string()
    .min(12, "Password must contain at least 12 characters")
    .matches(/\w*[a-z]\w*/, "Password must contain a lower case letter")
    .matches(/\w*[A-Z]\w*/, "Password must contain an upper case letter")
    .matches(/\d/, "Password must contain a number")
    .matches(
      /[!+@#$%^&*()\-_"=+{}; :,<.>]/,
      "Password must contain a special character or a space"
    )
    .strict(true)
    .trim("Password must not contain a leading or trailing space")
    .required("Password is required"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords does not match")
  //   .required("Confirm password is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  confirmAge: Yup.bool()
    .required()
    .oneOf([true], "This field is required")
});

export default function Signup({ userHasAuthenticated, isAuthenticated }) {
  useEffect(() => {
    applyMetaAndLoadTags('create_account');
  });


  const history = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  async function register(fields) {
    setIsLoading(true);
    setError(null);

    try {
      // Sign up the user
      const { user } = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          given_name: fields.firstName,
          family_name: fields.lastName,
          "custom:ageConfirmation": fields.confirmAge ? "true" : "false",
          // "custom:MarketingCommConfirmation": fields.marketingComm ? "true" : "false",
        },
        // autoSignIn: {
        //   enabled: true,
        // },
      });
      setIsLoading(false);
      setNewUser({ user, ...fields });
    } catch (e) {
      if (e.message === 'An account with the given email already exists.') {
        setError((
          <span>
            This email address is already in use, please click here to use forgot password.
            {" "}
            <Button 
              size="sm"
              type="button"
              disabled={isLoading}
              variant="link"
              onClick={() =>
                history({
                  pathname: "/reset",
                  search: window.location.search,
                })
              }
              className="mt-1 mx-auto"
              itemscope itemtype="http://tracking.3ds.com/ClickAction"
              >
              <meta itemprop="category" content="navigation" />
              <meta itemprop="action" content="internal_link" />
              <meta itemprop="label" content="Forgot password?" />
              Forgot password?
            </Button>
          </span>
        ));
      } else {
        console.error(e);
        setError(e.message);
      }

      setIsLoading(false);
    }
  }

  function renderRegisterForm() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          // confirmPassword: "",
          confirmAge: false,
          // marketingComm: false,
        }}
        onSubmit={register}
        validationSchema={schema}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="Signup-form">
            <div className="title">
              <h1 className="h2">Create account</h1>
              {/* <p>
                This name will be printed on your badge. Your legal name, if
                different, can be provided in the event profile.
              </p> */}
            </div>

            {error && (
              <Alert
                variant="danger"
                onClose={() => setError(null)}
                dismissible
                className="m-0"
              >
                {error}
              </Alert>
            )}

            <Form.Group
              controlId="firstName"
              size="lg"
              className="position-relative"
            >
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                autoFocus
                type="text"
                value={values.firstName}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.firstName && !errors.firstName}
                isInvalid={errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="lastName"
              size="lg"
              className="position-relative"
            >
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                value={values.lastName}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.lastName && !errors.lastName}
                isInvalid={errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="email"
              size="lg"
              className="position-relative"
            >
              <Form.Label>
                Business email
                <div>This will also be used as your username</div>
              </Form.Label>
              <Form.Control
                required
                type="email"
                value={values.email}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.email && !errors.email}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="password"
              size="lg"
              className="position-relative"
            >
              <Form.Label>Password</Form.Label>
              <PasswordInput
                required
                value={values.password}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.password && !errors.password}
                isInvalid={errors.password}
              />
              {/* <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type={
                  Yup.string()
                    .matches(/\w*[a-z]\w*/)
                    .isValidSync(values.password)
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string()
                  .matches(/\w*[a-z]\w*/)
                  .isValidSync(values.password) ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must contain a lower case letter
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type={
                  Yup.string()
                    .matches(/\w*[A-Z]\w*/)
                    .isValidSync(values.password)
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string()
                  .matches(/\w*[A-Z]\w*/)
                  .isValidSync(values.password) ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must contain an upper case letter
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type={
                  Yup.string().matches(/\d/).isValidSync(values.password)
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string().matches(/\d/).isValidSync(values.password) ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must contain a number
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type={
                  Yup.string().min(12).isValidSync(values.password)
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string().min(12).isValidSync(values.password) ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must contain at least 12 characters
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type={
                  Yup.string()
                    .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/)
                    .isValidSync(values.password)
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string()
                  .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/)
                  .isValidSync(values.password) ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must contain a special character or a space
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type={
                  Yup.string()
                    .strict(true)
                    .trim()
                    .isValidSync(values.password) && values.password.length
                    ? "valid"
                    : "invalid"
                }
              >
                {Yup.string()
                  .strict(true)
                  .trim()
                  .isValidSync(values.password) && values.password.length ? (
                  <FaCheck />
                ) : (
                  <FaTimes />
                )}
                Password must not contain a leading or trailing space
              </Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group
              controlId="confirmPassword"
              size="lg"
              className="position-relative"
            >
              <Form.Label>
                Confirm password
              </Form.Label>
              <PasswordInput
                required
                onChange={handleChange}
                value={values.confirmPassword}
                disabled={isLoading}
                isValid={touched.confirmPassword && !errors.confirmPassword}
                isInvalid={errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group> */}
            <p className="Signup-info">
            
              Dassault Systèmes Solidworks Corporation needs to collect certain personal data from you in order for you to attend the in person event,&nbsp;
               <strong><strong>3D</strong>EXPERIENCE<sup>&reg;</sup> World 2024</strong>. The personal data collected for this purpose will be handled by Stova, a subcontractor acting on behalf of 
              Dassault Systèmes SolidWorks Corporation.</p>
              <p>I hereby consent to the processing of my personal data by Dassault Systèmes SolidWorks Corporation 
              and its processor Stova for the purpose of attending <strong><strong>3D</strong>EXPERIENCE<sup>&reg;</sup> World 2024</strong> and acknowledge that 
              I have read and hereby accept the 
              {" "}
              <a
                href="https://discover.3ds.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>{" "}
              under which my personal data will be processed by Dassault Systèmes SolidWorks Corporation and its processor for this purpose.
              </p>
              <p>
              Furthermore, I agree to the  
              {" "}
              <a
                href="https://19963dexperiencewoprod.blob.core.windows.net/web/3DXWorld-2024-T+Cs-2024.docx?sv=2019-07-07&sr=c&si=web&sig=%2B1jzOKW2mY9jsJ61e%2BqxJLzXgYRo2ei06VtgTd7fy5M%3D"
                target="_blank"
                rel="noreferrer"
              >
                Event Terms and Conditions
              </a>{""}, which includes the event cancellation and refund policy.
              </p>

              

            <Form.Group controlId="confirmAge" size="lg">
              <Form.Check
                required
                name="confirmAge"
                label="I agree*"
                onChange={handleChange}
                isInvalid={!!errors.confirmAge}
                feedback={errors.confirmAge}
                feedbackType="invalid"
              />
            </Form.Group>

            {/* <p className="Signup-info">
              I agree to receive marketing communications from Dassault Systèmes Solidworks Corporation and its business partners.
            </p>

            <Form.Group controlId="marketingComm" size="lg">
              <Form.Check
                required
                name="marketingComm"
                label="Yes, I agree*"
                onChange={handleChange}
                isInvalid={!!errors.marketingComm}
                feedback={errors.marketingComm}
                feedbackType="invalid"
              />
            </Form.Group> */}
            
            
            <Button
              block
              size="lg"
              type="submit"
              variant="primary"
              disabled={isLoading}
              className="mt-4"
              itemscope itemtype="http://tracking.3ds.com/ClickAction"
            >
              <meta itemprop="category" content="navigation" />
              <meta itemprop="action" content="internal_link" />
              <meta itemprop="label" content="Create account" />
              Create account
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  if (isAuthenticated) {
    try {
      storeTokenAndRedirect();
    } catch (e) {
      console.error(e);
    }
    return <Loading />;
  }

  return (
    <div className="Signup">

        <div className="header">
        <Navbar />
        </div>
        <div className="main">
        <Container>
        {newUser === null ? (
          renderRegisterForm()
        ) : (
          <VerificationForm
            userHasAuthenticated={userHasAuthenticated}
            user={newUser}
            isAuthenticated={isAuthenticated}
            goBackHandler={() => setNewUser(null)}
          />
        )}
        </Container>
        </div>
        {/* <Footer /> */}
      
    </div>
  );
}
