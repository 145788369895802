export function applyMetaAndLoadTags(pageName) {
    const pagePath = `/3experience_world/events/registration/${pageName}`;

    document.querySelector("meta[property='tk:name']").setAttribute("content", pagePath);

    const scriptElem = document.getElementById('ds_tracking'); 

    if (scriptElem) {
      scriptElem.remove();
    }

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://tracking.3ds.com/stat/init/ds_init_global.js';    
    script.id = 'ds_tracking';

    document.head.appendChild(script);
}